<template>
  <v-card v-if="!fetchingCategories" :width="categoryListWidth" class="rounded-xl mx-auto category-list" flat>
    <v-row align="center" justify="center" no-gutters>
      <v-col v-for="(category, index) in categories" :key="index" class="pa-2" cols="auto">
        <v-card v-if="index < 8" class=" rounded-xl" elevation="0" tile>
          <kurcc-category-card :category="category" :index="index"/>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: 'KurccCategoryCardList',
  components: {
    KurccCategoryCard: () => import('@/modules/home/components/KurccCategoryCard')
  },
  data () {
    return {
      categories: [],
      fetchingCategories: false
    }
  },
  computed: {
    categoryListWidth () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 202
        case 'sm':
          return 396
        case 'md':
          return 612
        case 'lg':
          return 912
        case 'xl':
          return 1222
        default:
          return 202
      }
    }
  },
  methods: {
    getAllCategories () {
      this.fetchingCategories = true
      this.$store.dispatch('getAllCategories', { withIncludes: false })
        .then(res => {
          this.categories = res.items
        })
        .finally(() => {
          this.fetchingCategories = false
        })
    }
  },
  created () {
    this.getAllCategories()
  }
}
</script>

<style lang="scss" scoped>
.category-list {
  padding: 4px;
  margin-top: -112px;
}
</style>
